import type { Mixpanel } from "mixpanel-browser";

export function trackMixPanel<Untyped extends false | true = false>(
  mixpanel: Nullish<Mixpanel>,
  event: Untyped extends true ? UntypedEvent : Event,
  _options?: { untyped: Untyped },
) {
  mixpanel?.track(event.name, event.property);
}

export function registerMixPanelParameters(
  mixpanel: Nullish<Mixpanel>,
  parameters: Record<string, unknown>,
) {
  mixpanel?.register(parameters);
}

type UntypedEvent = {
  name: string;
  property: Record<string, unknown>;
};

type Event = BookmarkArticleEvent | SubscriptionMeteringEvent;

type BookmarkArticleEvent = {
  name: "Bookmark Article";
  property: {
    Action: "Add" | "Remove";
    Location: BookmarkArticleLocation;
  };
};
export type BookmarkArticleLocation =
  | "Article"
  | "Author Page"
  | "Homepage"
  | "Other Index"
  | "Section"
  | "Topic Index"
  | `${"Article"} ${"Adhesive" | "Side"}`;

type SubscriptionMeteringEvent = {
  name: "Subscription Metering";
  property: {
    "Metering Article Count": string;
    "User Action": "Click Subscribe" | "Impression";
  };
};
